import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {Link} from "gatsby"

const Tag = ({pageContext}) => {  

  const {allTags } = pageContext


  return (
    <Layout>
      <Seo
        title="Marco Gomes"
        description="Web Developer"
      />
      
        <h2>Tags</h2>
      
      <div className="tag">
        { allTags.map( tag => (
          <Link to={`${tag.uri}`} key={tag.id} className="tag__link">
            {tag.name}
          </Link> 
          ))
        }
      </div>
   
    </Layout>
  )
}

export default Tag